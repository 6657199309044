import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageContainer from "../components/pageContainer"
import Container from "../components/container"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageContainer>
      <Container>
        <h1>NOT FOUND</h1>
        <p>Sorry, it appears that page doesn't exist.</p>
      </Container>
    </PageContainer>
  </Layout>
)

export default NotFoundPage
